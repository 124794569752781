#grad1 {
  background-color: #171C38;
  background-image: linear-gradient(120deg, #FF4081, #171C38)
}

/*.container{
  max-width: 1399px;
}*/

#msform {
  text-align: center;
  position: relative;
  margin-top: 20px
}

#msform fieldset .form-card {
  background: white;
  border: 0 none;
  border-radius: 0px;
  box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.2);
  padding: 20px 40px 30px 40px;
  box-sizing: border-box;
  width: 94%;
  margin: 0 3% 20px 3%;
  position: relative
}

#msform fieldset {
  background: white;
  border: 0 none;
  border-radius: 0.5rem;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding-bottom: 20px;
  position: relative
}

#msform fieldset:not(:first-of-type) {
  display: none
}

#msform fieldset .form-card {
  text-align: left;
  color: #9E9E9E
}

#msform input,
#msform textarea {
  padding: 0px 4px 0px 4px;
  border: none;
  border-bottom: 1px solid #ccc;
  border-radius: 0px;
  margin-bottom: 25px;
  margin-top: 2px;
  width: 100%;
  box-sizing: border-box;
  font-family: montserrat;
  color: #000000;
  font-size: 14px;
}

#msform input:focus,
#msform textarea:focus,
#msform input:active,
#msform textarea:active {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: none;
  border-bottom: 2px solid #171C38;
  outline-width: 0 !important;
  outline: none;
}

#msform .action-button {
  width: 100px;
  background: #171C38;
  color: white;
  border: 0 none;
  border-radius: 0px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 5px;
  border-radius: 8px;
}

#msform .action-button:hover,
#msform .action-button:focus {
  box-shadow: 0 0 0 2px white, 0 0 0 3px #171C38
}

#msform .action-button-previous {
  width: 100px;
  background: #616161;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 0px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 5px
}

#msform .action-button-previous:hover,
#msform .action-button-previous:focus {
  box-shadow: 0 0 0 2px white, 0 0 0 3px #616161
}

select.list-dt {
  border: none;
  outline: 0;
  border-bottom: 1px solid #ccc;
  padding: 2px 5px 3px 5px;
  margin: 2px
}

select.list-dt:focus {
  border-bottom: 2px solid #171C38
}

.card {
  z-index: 0;
  border: none;
  border-radius: 0.5rem;
  position: relative
}

.fs-title {
  font-size: 25px;
  color: $primary;
  margin-bottom: 10px;
  font-weight: bold;
  text-align: left
}

.fs-title-right {
  font-size: 25px;
  color: $primary;
  margin-bottom: 10px;
  font-weight: bold;
  text-align: right;
}

.fs-subtitle {
  font-size: 15px;
  color: $secondary;
  margin-bottom: 10px;
  font-weight: bold;
  text-align: left;
}

.fs-title-secondary-18 {
  font-size: 18px;
  font-weight: bold;
}

.fs-title-secondary-16 {
  font-size: 16px;
  font-weight: bold;
}

.fs-title-secondary-14 {
  font-size: 14px;
  font-weight: bold;
}

.cursor-pointer-text {
  cursor: pointer;
}

.align-two-text-padding-top {
  padding-top: 25px;
}

.align-two-text-flex {
  display: flex;
  justify-content: space-between;
}

#progressbar {
  margin-bottom: 30px;
  overflow: hidden;
  color: lightgrey
}

#progressbar .active {
  color: #000000
}

#progressbar li {
  list-style-type: none;
  font-size: 12px;
  float: left;
  position: relative
}

.progress-bar-25{
  width: 25%;
}

.progress-bar-20{
  width: 20%;
}

#progressbar #events:before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f133";
}

#progressbar #mapevent:before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f279";
}

#progressbar #personal:before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f007";
}

#progressbar #tickets:before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f3ff";
}

#progressbar #payment:before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f09d";
}

#progressbar #confirm:before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f058";
}

#progressbar li:before {
  width: 50px;
  height: 50px;
  line-height: 45px;
  display: block;
  font-size: 18px;
  color: #ffffff;
  background: lightgray;
  border-radius: 50%;
  margin: 0 auto 10px auto;
  padding: 2px
}

#progressbar li:after {
  content: '';
  width: 100%;
  height: 2px;
  background: lightgray;
  position: absolute;
  left: 0;
  top: 25px;
  z-index: -1
}

#progressbar li.active:before,
#progressbar li.active:after {
  background: $primary;
}

.radio-group {
  position: relative;
  margin-bottom: 25px
}

.radio {
  display: inline-block;
  width: 204px;
  height: 104px;
  border-radius: 0;
  background: #1f2278;
  box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  cursor: pointer;
  margin: 8px 2px
}

.radio:hover {
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.3)
}

.radio.selected {
  box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.1)
}

.fit-image {
  width: 100%;
  object-fit: cover
}

list-group {
  background: #ffffff;
  color: #ff0000;
}

div list-group:checked,
div list-group:focus {
  background: linear-gradient(#ff0000, #ff0000);
  -webkit-text-fill-color: #ffffff;
  color: #ffffff;
}

.icons-table-users{
  color: $primary;
  margin-left: 15px;
  margin-bottom: 20px;
  font-size: 20px;
}

.icons-table-users:hover{
  color:$secondary;
}

.icons-table{
  float:right;
}

.badge-success-payment{
  position: absolute;
  z-index: 1;
  margin-left: 18rem;
  margin-top: 7px;
}

// Overlay
.overlay-event-menu {
  z-index: 9999999;
}

.divider {
  position: relative;
  background-color: gray;
  display: inline-block;
  margin: 0 0.25%;
  height: 3px;
  width: 100px;
}

.badge-event-selected{
  position: absolute;
  padding: 7px;
  margin-left: -10px;
  margin-top: -10px;
}

//Spinner
.center-spinner-loader{
  margin: auto;
  top:0;
  bottom: -65px;
  left: 0;
  right: 0;
}

.line-through-text {
  text-decoration:line-through;
  color: red;
}

// Seat map
.map_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  box-shadow: 0px 12px 76px 0 rgb(145 158 171 / 10%);
  padding: 20px 50px;
  z-index: 99999999;
  border-radius: 8px;
  overflow: hidden !important;
}

.category {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.seats_row {
  display: flex;
  align-items: center;
}

.seats_vertial_row {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  .seat_circle {
    background-color: $primary;
  }

  .verticle-row {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.seats_vertial_row .map-row {
  flex-direction: column;
}

.margin-h {
  margin: 0 5px;
}

.empty_space {
  background-color: transparent;
}

.map-row {
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-size: 12px;
  }
}

.seprator {
  margin: 20px 0;
}

.category_text_sec {
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 70px;

  .category_div {
    display: flex;
    min-width: 230px;
    justify-content: space-between;
  }

  .category_heading {
    text-transform: uppercase;
    color: #333;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    margin-bottom: 5px;
  }

  .category_secondry_color {
    background-color: #f17732 !important;
  }

  .category_color {
    display: inline-block;
    width: 120px;
    height: 20px;
    background-color: $primary;
  }
}

.category-2 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 10px 0;

  #margin-left {
    margin-left: 50px !important;
  }

  .seats_row {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .seat_circle {
    background-color: $primary;
  }
}

.category-2-left-row,
.category-2-right-row {
  width: 30px;

  .seat_circle {
    background-color: $primary;
    margin: 1.5px 0;
  }
}

#margin-left {
  margin-left: 100px;
}

.palco_btn {
  cursor: pointer;
  border: 2px solid #333;
  background-color: transparent;
  width: 230px !important;
  margin: 20px auto;
  text-align: center;
}

.react-transform-wrapper {
  margin: 0px auto !important;
  width: 33vw !important;
  height: 50vh !important;
}

.zoom-wrapper {
  width: 100% !important;
}

.zoom-seat-map .btn-area:hover {
  cursor: pointer;
}

.zoom-seat-map {
  position: absolute;
  right: 130px;
  bottom: 650px;
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  z-index: 9999;

  .overlay-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    .overlay-text {
      color: #fff;
      font-size: 65px;
      width: 50%;
      padding: 0 62px;
    }
  }
}

.zoom-button {
  padding: 7px 10px;
}

#small-font {
  font-size: 6px !important;
}

.booked {
  // background-color: #666666 !important;
  opacity: 1 !important;
}

.selected {
  // background-color: #209f84 !important;
}

.map-filters {
  position: absolute;
  top: 150px;
  right: 26px;
  z-index: 99999;
  height: 0px;
  overflow: hidden;
  opacity: 0;
  transition: 0.3s ease;
  filter: drop-shadow(0px 0px 12px rgba(198, 198, 198, 0.25));

  .presense.content-wrapper {
    min-width: 230px;
    min-height: 180px;
    height: fit-content;
    border-radius: 8px;
    background-color: #ffff;
    padding: 8px;


    .body {
      .zone-color {
        width: 30px;
        height: 30px;
        border-radius: 5px;
        margin-right: 5px;
      }

      .role--item {
        padding: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        .item--body {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
          color: rgb(15, 15, 15);
          margin-left: 8px;
        }

        .filter-radio {
          width: 20px !important;
          height: 20px;
          margin: 0 !important;
          accent-color: #171c38
        }
      }
    }

  }
}

.filter-button {
  position: absolute;
  right: 50px;
  top: 125px;
  z-index: 9999999999;
}

.dropdown--visible {
  min-height: 180px;
  opacity: 1;
  height: fit-content;
}

.text-align-right {
  text-align: right;
}


@media (min-width: 1080px) {
  .react-transform-wrapper {
    height: fit-content !important;
  }
}

.size-img-card-payment{
  width: 150px;

}

.align-two-card-flex {
  display: flex;
}

@media screen and (max-width: 768px) {
  .align-two-card-flex {
    display: block;
  }
  .react-transform-wrapper {
    margin: 0px auto !important;
    width: 70vw !important;
    height: 50vh !important;
  }

}

@media screen and (max-width: 359px) {
  .zoom-seat-map {
    position: absolute;
    right: 200px;
    bottom: 110px;
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
  }
  .filter-button {
    position: absolute;
    right: 120px;
    top: 80px;
    z-index: 9999999999;
  }
}

@media screen and (min-width: 360px) and (max-width: 390px) {
  .zoom-seat-map {
    position: absolute;
    right: 220px;
    bottom: 110px;
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
  }
  .filter-button {
    position: absolute;
    right: 140px;
    top: 80px;
    z-index: 9999999999;
  }
}

@media screen and (min-width: 391px) and (max-width: 699px) {
  .zoom-seat-map {
    position: absolute;
    right: 260px;
    bottom: 110px;
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
  }
  .filter-button {
    position: absolute;
    right: 180px;
    top: 80px;
    z-index: 9999999999;
  }
}

@media screen and (min-width: 700px) and (max-width: 768px) {
  .zoom-seat-map {
    position: absolute;
    right: 20px;
    bottom: 150px;
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
  }
  .filter-button {
    position: absolute;
    right: -60px;
    top: 80px;
    z-index: 9999999999;
  }
}


@media screen and (min-width: 769px) and (max-width: 1079px) {
  .zoom-seat-map {
    position: absolute;
    right: 90px;
    bottom: 130px;
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
  }
  .filter-button {
    position: absolute;
    right: 10px;
    top: 125px;
    z-index: 9999999999;
  }
}

@media screen and (min-width: 1080px) and (max-width: 1680px) {
  .zoom-seat-map {
    position: absolute;
    right: 65px;
    bottom: 650px;
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
  }
  .filter-button {
    position: absolute;
    right: 15px;
    top: 125px;
    z-index: 9999999999;
  }
}

.hidden-hour-event {
  visibility: hidden;
}


.overlay-map{
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  background-size: 50px;
  z-index: 1;
  opacity: .6;
  border-radius: 50px;
  border-width: 1px;
  color: #fff;
}
